.content-grid {
    grid-area: content-grid;
    display: grid;
    width: 100%;
    grid-template-areas:
	" content-main ";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 0;   
}

.content-general {
    //    background-color: red;
    min-height: 84.1vh;
}
.content-main {
    grid-area: content-main;
}
.content-bumper-left {
    grid-area: content-bumper-left;
}
.content-bumper-right {
    grid-area: content-bumper-right;
}


@media (min-width: 600px) {
    .content-grid {
	grid-template-areas:
	    " content-bumper-left  content-main  content-bumper-right ";
	grid-template-rows: 1fr;
	grid-template-columns:
	    minmax(20px, auto)
	    minmax(10vw, 2000px)
	    minmax(20px, auto);
    }
}


@import "./colors.scss";
@import "./fonts.scss";

.navbar-grid {
    grid-area: navbar-grid;
    display: grid;
    width: 100%;
    grid-template-areas:
	" navbar-main "
	" navbar-hr ";
    grid-template-rows: 60px 0;
    grid-template-columns: 1fr;
    grid-gap: 0;
  //  position: fixed; /* Set the navbar to fixed position */
  //  top: 0; /* Position the navbar at the top of the page */    
}

.navbar-general {
    //    background-color: grey;
}

.navbar-main {
    grid-area: navbar-main;
    padding: 5px;
}
.navbar-bumper-left {
    grid-area: navbar-bumper-left;
}

.navbar-bumper-right {
    grid-area: navbar-bumper-right;
}

.navbar-hr {
    border-top: 0.25px solid gray;
    grid-column: 1 / span 1;
    margin: 1px;
    border-radius: 50px;    
    -webkit-box-shadow: 0px 2px 4px 0px grey;
    -moz-box-shadow: 0px 2px 4px 0px grey;
    box-shadow: 0px 2px 4px 0px grey;
}

.nav-links {
    display: none;
}

.nav-link {
    min-width: 80px;
    display: inline-block;
    text-align: center;
    padding: 6px 2px;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 4px;
    //border: 0.5px solid red;
//    background-color: $main-grey-light;
//    box-shadow: 0px 4px 8px 0px $main-grey-light;
}
.nav-link:hover {
    background-color: $main-yellow;
    // opacity: 0.2;  
}

.nav-menu {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}


@media (min-width: 600px) {
    .navbar-grid {
	grid-template-areas:
	    " navbar-bumper-left navbar-main navbar-bumper-right "
	    " navbar-hr          navbar-hr   navbar-hr ";
	grid-template-rows: 60px 0;
	grid-template-columns:
	    minmax(20px, auto)
	    minmax(10vw, 2000px)
	    minmax(20px, auto);
    }
    .navbar-hr {
	grid-column: 1 / span 3;
    }
    .nav-links {
	display: block;
    }
    .nav-menu {
	display: none;
    }
}



.logo {
    // height: 25px;
    height: 100%;
    margin-right: 5px;    
}

.logo-div {
    display: block;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;    
}

@media only screen and (max-width: 600px){
    .logo {
//	height: 20px;       
   }
}

// .image2{
//    display: none;
// }

// @media only screen and (max-width: 500px){ //some value
//    .image1{
//      display: none;
//    }

//    .image2{
//      display: block;
//    }
// }


.nav-flex {
  display: flex;
  justify-content: left;
  align-content: center;
  overflow: hidden;
}

.list-right {
  display: flex;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.to-link {
    border-radius: 2px;
}


navbar-item,
.navbar-link,
.dropbtn {
  min-width: 100px;
  display: inline-block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

// .navbar-link:hover,
// .dropdown:hover {
//   background-color: $pt-intent-danger;
// }

// li .dropdown {
//   display: inline-block;
// }

.dropdown-content {
  display: none;
  position: absolute;		
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: $pt-intent-primary;
  &.dark {
    background-color: $pt-intent-primary;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  background-color: $dark-gray3;
  &.dark {
    background-color: $light-gray3;
  }
}

// .navbar-link,
// .dropdown-content a {
//   color: $pt-dark-heading-color;
//   &.dark {
//     color: $pt-heading-color;
//   }
// }

@import "./colors.scss";
@import "./fonts.scss";

.footer-grid {
    grid-area: footer-grid;
    display: grid;
    width: 100%;
    grid-template-areas:
	" footer-main ";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 0;
    background-color: grey;
}

.footer-general {
    //    background-color: green;
   // position: fixed;
   bottom: 0;
   width: 100%;
   // z-index: 999;
   display: grid;
   justify-items: center;
   align-items: start;
   background: $main-grey-dark;   
}
.footer-main {
    grid-area: footer-main;
}
.footer-bumper-left {
    grid-area: footer-bumper-left;
}
.footer-bumper-right {
    grid-area: footer-bumper-right;
}


@media (min-width: 600px) {
    .footer-grid {
	grid-template-areas:
	    " footer-bumper-left  footer-main  footer-bumper-right ";
	grid-template-rows: 1fr;
	grid-template-columns:
	    minmax(20px, auto)
	    minmax(10vw, 2000px)
	    minmax(20px, auto);
    }
}

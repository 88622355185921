.main-grid {
    display: grid;
    width: 100%;
    grid-template-areas:
	" navbar-grid "
	" content-grid "
	" footer-grid ";
    grid-template-rows: 60px minmax(200px, auto) minmax(200px, 400px);
    grid-template-columns: 1fr;
    grid-gap: 0;   
}


@import "./components/colors.scss";
@import "./components/fonts.scss";

* {
  box-sizing: border-box;
}

html, body, #root {
    padding: 0;
    margin: 0;
    width: 100%;
}


// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }


// body {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;  
// }


.bp-app-background {
  background: $light-gray5;
  &.dark {
    background: $dark-gray4;
  }
}

.bp-content {
  background: $white;
  &.dark {
    background: $dark-gray3;
  }
}

.bp-button {
  background: $light-gray5;
  &.dark {
    background: $dark-gray5;
  }
}

.bp-button-alt {
  background: $dark-gray5;
  &.dark {
    background: $light-gray5;
  }
}

.bp-panel {
  background: $light-gray4;
  &.dark {
    background: $dark-gray5;
  }
}

.bp-nav {
  background: $dark-gray3;
  &.dark {
    background: $light-gray5;
  }
}


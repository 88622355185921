/* Import variables from blueprint (mainly colors and color schemes) */
@import "./colors.scss";

/* 
 * Font import
 * Using this guide => https://www.zachleat.com/web/css-tricks-web-fonts/
 */

@font-face {
  font-family: "Inconsolata";
  src: url("../../public/fonts/Inconsolata-Regular-kern-latin.woff2")
    format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Taviraj";
  src: url("../../public/fonts/Taviraj-Light-kern-latin.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Taviraj-Italic";
  src: url("../../public/fonts/Taviraj-LightItalic-kern-latin.woff2")
    format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Taviraj-Black";
  src: url("../../public/fonts/Taviraj-Black-kern-latin.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../../public/fonts/Montserrat-Regular-kern-latin.woff2")
    format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-Black";
  src: url("../../public/fonts/Montserrat-Black-kern-latin.woff2")
    format("woff2");
  font-display: swap;
}

.text-title-black {
  font-family: "Montserrat-Black", sans-serif;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-title {
  font-family: "Montserrat", sans-serif;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-body {
  font-family: "Taviraj", serif;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-body-italic {
  font-family: "Taviraj-Italic", serif;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-body-black {
  font-family: "Taviraj-Black", serif;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-code {
  font-family: "Inconsolata", monospace;
  color: $pt-heading-color;
  &.dark {
    color: $pt-dark-heading-color;
  }
}

.text-button {
  font-family: "Montserrat", sans-serif;
}

.text-nav {
    font-family: "Montserrat", sans-serif;
    font-size: 0.8em;
    color: $main-grey-dark;    
}

.text-nav-title {
    text-justify: center;
    // margin-bottom: 2px;
    font-family: "Taviraj-Black", sans-serif;
    font-size: 1.2em;
    color: $main-grey-dark;
    text-decoration: none;
}

.text-footer {
    display: block;
    margin: 100px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 2.5em;
    color: $main-grey-light;
}

@import "./colors.scss";
@import "./fonts.scss";

.card {
  display: grid;
  padding: 0;
  margin: 5px;
 
}

.card-container {
    padding: 2px;
//    border: 0.5px solid rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);    
    h1 {
	font-size: 1em;
    }
    p {
	font-size: 0.5em;
    }
}



.caption {
  display: grid;
  align-items: end;
  justify-items: center;
  /*   padding: 1em; */
//  color: var(--iah-light);
  //  background: var(--iah-red);
//  color: red;
  background: $main-yellow;
  opacity: 0;
  transition: all 0.4s;
}

.card-image {
    /*   display: inline; */
    width: 100%;
    object-fit: cover;
    padding: 0;
//    max-height: 60vh;
    aspect-ratio: 3 / 2 !important;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
//    border: 0.5px solid rgba(0, 0, 0, 0.8);  
}

.caption-title {
  margin: 0;
  padding: 5px 5px 15px 5px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 0.5em;
  //  color: var(--iah-light);
  color: lightgrey;
  text-decoration: none;
}

.card-image,
.caption {
  grid-column: 1;
  grid-row: 1;
}

a:focus .caption,
a:hover .caption {
  opacity: 0.2;
}

.carousel-image {
  display: inline;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
}

.active {
    //  border: 4px solid var(--iah-yellow);
  border: 4px solid $main-yellow;
}

@media (min-width: 1000px) {
  .caption-title {
    font-size: 1em;
  }
}

@media (min-width: 600px) {
  .caption-title {
    font-size: 0.6em;
  }
}

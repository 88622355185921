
.projects {
    //  grid-area: projects;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 200px); // minmax(400px, 1fr));
    grid-auto-rows: 1fr;
    grid-auto-flow: dense;
    grid-gap: 25px;
    margin: 20px;
    padding: 20px;
//    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.project-select {
    width: 400px;
}

@media (min-width: 600px) {
    .projects {
	grid-template-columns: repeat(auto-fit, 460px); // minmax(400px, 1fr));
    }
}

